//
// SweetAlert2
//

// Base
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open) {
	overflow-y: initial !important;

	&:not(.sweetalert2-nopadding) {
		padding-right: 0 !important; 
	}
}

// Popup
.swal2-popup {
	background-color: var(--cardBackgroundColor) !important;
	padding: 2rem !important;
	border-radius: 7px !important;

	.swal2-title {
		font-weight: 500 !important;
		font-size: 1.3rem !important;
		color: white !important;
	}

	.swal2-html-container,
	.swal2-content {
		font-weight: normal !important;
		font-size: 1.1rem !important;
		margin-top: 1.5rem !important;
		color: #CDCDDE !important;
	}

	.btn {
		margin: 15px 5px 0 !important;
	}

	.swal2-styled {
        &.swal2-confirm {
            border-color: var(--buttonBgColor) !important;
            background-color: var(--buttonBgColor) !important;

        }
		&:focus {
			box-shadow: none !important;
		}
	}

	.swal2-actions {
		margin: 1.5rem auto 1rem auto !important;
	}
}

// Container
.swal2-container {
	overflow-y: hidden !important;

	&.swal2-shown {
		background-color: rgba(black, 0.2) !important;
	}

	// Content
	.swal2-html-container {
		max-height: 200px !important;
		overflow: auto !important;
	}
}

body.swal2-height-auto {
	height: 100% !important;
}

// State Colors
.swal2-icon {
	// Warning
	&.swal2-warning {
    	border-color: #ffc700 !important;
    	color: #ffc700 !important;
	}

	// Error
	&.swal2-error {
    	border-color: rgb(241, 65, 108) !important;
    	color: rgb(241, 65, 108) !important;

		[class^=swal2-x-mark-line] {
			background-color: rgba(241, 65, 108, 0.75) !important;
		}
	}

	// Success
	&.swal2-success {
    	border-color: #50cd89 !important;
    	color: #50cd89 !important;

		[class^=swal2-success-line] {
			background-color: #50cd89 !important;
		}

		.swal2-success-ring {
			border-color: rgba(80,205,137, 0.3) !important;
		}
	}

	// Info
	&.swal2-info {
    	border-color: var(--buttonBgColor) !important;
    	color: var(--buttonBgColor) !important;
	}

	// Question
	&.swal2-question {
    	border-color: #009ef7 !important;
    	color: #009ef7 !important;
	}
}
