.streamHistoryPage {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    .streamHistoryContainer {

        .listenHistory {

            padding: 10px;

            .historyElement {

                display: flex;
                padding: 5px 10px;
                border-radius: 7px;

                animation: fadeIn;
                animation-duration: 1s;
            
                img {
                    width: 64px;
                    height: 64px;
                    border-radius: 5px;
                }

                .trackInfos {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 1;
                    padding: 0 10px;
                }

                transition: all 0.1s ease;
                &:hover {
                    transition: all 0.1s ease;
                    background-color: #2b2b40;
                }
            }

            .focus {
                background-color: #2b2b40;
            }
        }
    }
}

* {
	scrollbar-width: 0;
}

*::-webkit-scrollbar {
	background-color: transparent;
	width: 5px;

    &:hover {
        width: 9px;
    }

}

*::-webkit-scrollbar-thumb {
	background-color: #858dc147;;

    transition: all 1s ease;
    &:hover {
        transition: all 1s ease;
        background-color: white;
        width: 9px;

    }
}

.cardLoadingContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
}