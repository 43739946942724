.profileLayout {
    display: flex;
    flex-direction: column;
    flex: 1;

    .profileLayoutHeader {
        
        .profilePageUserProfileContainer {
            margin: 15px 20px;
            background-color: var(--cardBackgroundColor);
            border-radius: 7px;
            box-shadow: var(--cardShadow);

            .profilePageUserProfileContent {
                display: flex;
                flex-direction: column;
                padding: 20px;

                .profileAvatarSection {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    flex-wrap: wrap;
                    justify-content: center;

                    .userAvatar {
                        img {
                            width: 156px;
                            height: 156px;
                            border-radius: 0.475rem;
                            // border: 1px solid var(--svgHoverColor);

                            object-fit: cover;
                            opacity: 0.8;
                            transition: opacity 0.5s;
                            transition: all ease 0.5s;

                            &:hover {
                                opacity: 1;
                                transition: opacity 0.5s;
                                transition: all ease 0.5s;

                                transform: scale(1.1);
                            }
                        }

                        .editMenu {
                            width: 156px;
                            height: 156px;
                            background-color: #43424c66;
                            display: block;
                            position: relative;
                            top: -156px;
                            border-radius: 0.475rem;

                        }
                    }

                    .userProfileContainer {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        padding: 15px;

                        .userProfileContent {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .displayName {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .username {
                                    font-family: "Jost";
                                    font-weight: 600;
                                    font-size: 40px;
                                    margin-right: 5px;
                                }

                                .roles {
                                    padding: 5px 0px;

                                    .badge {
                                        display: inline-block;
                                        padding: 0.325rem 0.5rem;
                                        font-size: 0.85rem;
                                        font-weight: 600;
                                        line-height: 1;
                                        color: white;
                                        text-align: center;
                                        white-space: nowrap;
                                        // vertical-align: baseline;
                                        border-radius: 0.425rem;
                                        background-color: #8f8fcc;
                                        margin: 5px;
                                    }
                                }
                            }

                            .profileActions {
                                padding: 15px 0px;

                                svg {
                                    cursor: pointer;
                                    color: #6b6b96;
                                    margin: 0 5px;

                                    &:hover {
                                        color: #9c9ce2;
                                    }
                                }
                            }
                        }

                        .userSocialContainer {
                            display: flex;

                            .socialUser {
                                display: flex;
                                align-items: center;
                                margin-right: 5px;

                                .socialName {
                                    font-family: "Poppins";
                                    font-weight: 600;
                                    color: #7777a1;
                                    margin-left: 5px;
                                }

                                .spotifyICO {
                                    width: 28px;
                                    height: 24px;
                                }

                                svg {
                                    cursor: pointer;
                                    width: 32px;
                                    height: 28px;

                                    path {
                                        fill: #8f8fcc;
                                    }

                                    &:hover {
                                        path {
                                            fill: #a8a8f2;
                                        }
                                    }
                                }
                            }
                        }

                        .userChartContainer {
                            display: flex;
                            align-items: flex-end;
                            flex: 1;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .profileLayoutHeader {
        .profilePageUserProfileContainer {
            margin: 15px 30px !important;

            .profileAvatarSection .userProfileContainer {
                padding: 0px !important;

                .userProfileContent {
                    margin: 15px;
                    justify-content: center !important;

                    .displayName {
                        .username {
                            font-size: 25px !important;
                        }
                    }
                    .profileActions {
                        display: none;
                    }
                }

                .userSocialContainer {
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
