:root {
    --scale: "1.5";
    --y: "0";
    overflow: hidden;
}

.homePage {
    width: 100vw;
    height: 100vh;
    border: none;
    
    .backgroundImg {
        width: 100vw;
        height: 100vh;
        background-image: url("https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2550&q=80");
        background-size: cover;
        transform: translateX(var(--x)) translateY(var(--y)) scale(var(--scale));
        transition: ease-out 0.5s;
    }

    .title {
        display: flex;
        position: fixed;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: none !important;
        -webkit-animation: fade-in 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: fade-in 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        background-color: rgba(146, 16, 232, 0.476);

        color: white;
        font-family: "Poppins";

        h1 {
            font-size: 4rem;
            letter-spacing: 6px;
        }

        p {
            margin-top: 20px;
        }
    }
}
