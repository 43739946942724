.dashboard {

    display: flex;
    flex-direction: row;
    flex: 1;
    
    .dashboardPage {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
    }
}