.dashboardHeaderContainer {

    display: flex;

    .dashboardHeaderContent {

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        padding: 5px 10px;

        .headerNavbar {

            a {
                text-decoration: none;
                color: white;
                padding: 13px;

                transition: all ease 0.5s;

                &:hover {

                    background-color: #262648;
                    transition: all ease 0.5s;

                }
            }

            .expandSidebarButton {
                display: none;
                color: #9c9ce2;
                border-radius: 7px;
                transition: all ease 0.5s;
                cursor: pointer;
            }
        }
        
        .headerProfile {

            display: flex;
            flex-direction: row;
            align-items: center;

            .userOptions {

                display: flex;
                margin-right: 10px;

                svg {
                    color: #9c9ce2;
                    padding: 5px;
                    margin: 3px;
                    border-radius: 7px;
                    transition: all ease 0.5s;
                    cursor: pointer;
    
                    &:hover {
                        transition: all ease 0.5s;
    
                        background-color: #8f8fcc;
                        color: white;
                    }
                }
            }

            .userAvatar {
    
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 0.475rem;
                    opacity: .8;
    
                    margin-top: 7px;
                    cursor: pointer;
    
                    transition: opacity 0.5s;
                    transition: all ease 0.5s;
    
                    &:hover {
                        opacity: 1;
                        transition: opacity 0.5s;
                        transition: all ease 0.5s;
    
                        transform: scale(1.1);
                    }
    
                }
    
                .userStatus {
                    display: block;
                    width: 7px;
                    height: 7px;
                    background-color: #50cd89;
                    position: relative;
                    top: -8px;
                    margin-left: auto;
                    border-radius: 100%;
    
                    transition: all ease 2s;
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {

    .dashboardHeaderContainer { 
        margin: 5px 10px;

        .dashboardHeaderContent {
            padding: none !important;
        }
    }

    .headerNavbar .expandSidebarButton {
        display: block !important;
    }
    
}