.themeSwitcherButtonComponent {

    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
        padding-right: 7px;
        font-size: 12px;
    }

    input {
        display: none;
    }

    .button {
        width: 45px;
        height: 20px;
        background: #202430;
        border-radius: 80px;
        overflow: hidden;
        cursor: pointer;
    
        transition: 0.3s;
        display: flex;
        align-items: center;

        &::before {
            content: "";
            margin: 0 5px;
            height: 15px;
            width: 15px;
            background: whitesmoke;
            border-radius: 80px;
            transition: 0.5s;
        }
    }

    .active {
        &::before {
            transform: translateX(112%);
            background: #202430;
            background: whitesmoke;
            box-shadow: 0 0 0 1200px #202430;
        }
    }
}
