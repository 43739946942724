.dashboardSidebarContainer {

    transition: width 0.5s ease;

    display: flex;
    width: 210px;

    background-color: var(--sidebarBgColor);
    box-shadow: var(--sidebarShadow);

    .dashboardSidebarContent {

        display: flex;
        flex-direction: column;
        flex: 1;

        justify-content: space-between;
        align-items: center;

        border-radius: 15px;
        margin-bottom: 30px;

        .sidebarLogo {

            display: flex;
            flex-direction: column;
            align-items: center;

            width: 100%;
            cursor: pointer;

            a {
                display: flex;
                text-decoration: none;
                color: white;

                img {
                    width: 48px;
                    height: 70px;
    
                    transition: all ease 1s;
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }

            .sidebarExpandButton {
                
                margin: 16px 0;
                margin-left: auto;

                position: relative;
                left: 17px;

                fill: #474774;

                transition: transform 0.7s ease;

                svg {
                    border: 2px solid #494974;
                    border-radius: 20px;
                    opacity: .7;
                    fill: #8f8fd9;
                    
                    transition: opacity 1s;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.1);
                        opacity: 1;
                    }
                }

            }
        }

        .sidebarRoutes {

            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;

            width: 100%;
            
            padding: 10px 0px;

            .sidebarGroup {
                margin: 5px;
                display: flex;

                font-family: "Inter";
                font-size: 13px;
                text-transform: uppercase;

                width: 85%;
                line-height: 18px;
                font-weight: 500;
                color: #838396;

            }

            .sidebarRoute {

                display: flex;
                align-items: center;

                width: 75%;
                
                margin: 5px 0px;
                padding: 10px;

                text-decoration: none;

                transition: all ease 0.5s;

                svg {
                    text-decoration: none;

                    width: 20px;
                    height: 20px;

                    color: var(--svgColor);

                    path {
                        fill: var(--svgColor);
                    }
                }

                .routeName {
                    font-weight: 400;
                    font-family: 'Poppins';
                    font-size: 14px;
    
                    text-decoration: none;
    
                    color: #9D9DA6;
    
                    margin-left: 10px;
                }

                &:hover {
                    background-color: var(--buttonHoverBgColor);
                    border-radius: 5px;

                    svg {
                        color: white;
                    }

                    .routeName {
                        color: white;
                    }
                }
            }

            .active {

                svg { color: white; }
                .routeName { color: white; }
            }
        }

        .sidebarFooter {
            display: flex;
            
            margin-bottom: 10px;
        }
    }
}

.dashboard .sidebarNotExpanded {

    width: 65px;

    .sidebarLogo a img {
        width: 28px;
        height: 40px
    }

    .routeName { display: none ;}

    .sidebarRoutes {

        .sidebarRoute {
            width: auto;
        }

        .sidebarGroup {
            width: auto;
        }
    }

    .sidebarExpandButton {
        transform: rotateZ(180deg);
        transition: transform 0.7s ease;
    }

}

@media screen and (max-width: 425px) {

    .dashboardSidebarContainer { 
        display: none;
    }

}
