.authLayout {

    display: flex;
    flex: 1;
    
    .authPageContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 50px;

        .authPageContent {
            display: flex;
            flex: 1 0 auto;
            justify-content: space-between;
            flex-direction: column;
            
            width: 100%;
            max-width: 485px;

            .header {
                margin-left: auto;
                color: white;
                
                a {
                    color: var(--linkTextColor);
                    text-decoration: none;
    
                    &:hover {
                        color: var(--linkHoverTextColor);
                    }
                }
            }

            .footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
        
                a {
                    text-decoration: none;
                    color: var(--linkTextColor);
        
                    &:hover {
                        color: var(--linkHoverTextColor);
                    }
                }
        
                .submitBtn {
                    background-color: var(--buttonBgColor);
                    padding: 16px 40px;
                    border-radius: 7px;
                    cursor: pointer;
        
                    transition: ease 0.5s;
        
                    &:hover { 
                        background-color: var(--buttonHoverBgColor);
                        transition: ease 0.5s;
                    }
                }
            }
        }

        .authErrorComponent {
            display: flex;
            justify-content: center;

            font-family: "Inter";
            padding: 20px;

            background-color: #f1416d38;
            border: 1px solid #f1416d74;
            border-radius: 5px;
        }
        
        .authForm {

            .authTitle {

                font-family: "Inter";
                padding: 20px 0px;
                
                .title {
                    font-size: 34px;
                    color: var(--textColor);
                    font-weight: 600;
                    margin-bottom: 10px;
                }
            
                .subTitle {
                    font-size: 13px;
                    color: var(--greyTextColor);

                }
            }

            .inputContainer {
                
                margin-bottom: 20px;

                // TODO: chrome autofill
                input {
                    background-color: var(--inputBgColor);
                    border-color: var(--inputBgColor);
                    border: 1px solid var(--inputBgColor);
                    color: var(--lightGreyTextColor);

                    box-sizing: border-box;

                    width: 100%;
                    padding: 14px 1rem;
                    font-size: 14px;
                    font-family: "Inter";
                    font-weight: 500;
                    line-height: 1.5;
                    appearance: none;
                    border-radius: 0.475rem;

                    transition: color 0.2s ease;
                    
                    &:focus, &:-webkit-autofill,
                    &:-webkit-autofill:hover, 
                    &:-webkit-autofill:focus {
                        background-color: var(--focusInputBgColor);
                        border-color: var(--focusInputBgColor);
                        color: var(--lightGreyTextColor);
                        box-shadow: none;
                        outline: none;
                    }

                    &::placeholder {
                        color: var(--placeholderTextColor);
                    }
                }

                .inputError {
                    font-size: 12px;
                    margin-left: 5px;
                    margin-top: 8px;
                    color: #f1416c;
                }
            }

            .submitContainer {
                display: flex;
                justify-content: flex-end;
                align-items: center;
        
                .submitBtn {
                    background-color: var(--buttonBgColor);
                    padding: 16px 40px;
                    border-radius: 7px;
                    cursor: pointer;
        
                    transition: ease 0.5s;
        
                    &:hover { 
                        background-color: var(--buttonHoverBgColor);
                        transition: ease 0.5s;
                    }
                }
            }
        }

        .oauthContainer {

            padding-top: 10px;

            .oauthTitle {

                text-align: center;
                margin: auto;
                font-family: "Poppins";
                color: #474761;
                padding: 10px;
                font-size: 14px;
                letter-spacing: 0.5px;

            } 

            .oauthProviders {

                display: flex;
                align-items: center;
                justify-content: center;

                padding-top: 10px;

                .provider {
                    width: 32px;
                    height: 32px;

                    background-color: var(--buttonBgColor);
                    padding: 11px;
                    border-radius: 15px;

                    margin: 0px 15px;
                    cursor: pointer;

                    transition: all 0.5s ease;

                    &:hover {
                        background-color: var(--buttonHoverBgColor);

                        svg > path {
                            fill: var(--svgHoverColor);
                        }
                    }

                    svg {
                        path {
                            fill: var(--svgColor);
                        }
                    }
                }
            }
        }
    }

    .illustrationAuth {
        display: flex;
        background-image: url("https://shareyourstream.pages.dev/assets/images/bg-login.png");
        width: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: center;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}

@media screen and (max-width: 1000px) {

    .authLayout > .illustrationAuth { display: none; }

}

@media screen and (max-width: 380px) {

    .authPageContent {
        .submitBtn { padding: 12px !important; }
    }

}