@import "./colors";

$themes: (
    bgColor: (
        darkTheme: $darkBlue300,
        lightTheme: #F5F8FA,
    ),
    textColor: (
        darkTheme: white,
        lightTheme: $darkBgColor,
    ),
    primaryTextColor: (
        darkTheme: $primaryTextColor,
        lightTheme: $primaryTextColor
    ),
    lightGreyTextColor: (
        darkTheme: #b1b1c2
    ),
    greyTextColor: (
        darkTheme: $primaryGrayDarkColor,
        lightTheme: $primaryGrayDarkColor
    ),
    inputBgColor: (
        darkTheme: #1b1b29,
        lightTheme: #d5d6e4
    ),
    focusInputBgColor: (
        darkTheme: #1f1f2f,
    ),
    placeholderTextColor: (
        darkTheme: #565674
    ),

    linkTextColor: (
        darkTheme: $lightGrey200,
        lightTheme: $lightGrey200,
    ),
    linkHoverTextColor: (
        darkTheme: $lightGrey100,
        lightTheme: $lightGrey100,
    ),
    buttonBgColor: (
        darkTheme: $darkBlue500,
        lightTheme: $darkBlue500
    ),
    buttonHoverBgColor: (
        darkTheme: $darkGrey400
    ),
    svgColor: (
        darkTheme: $darkGrey200,
        lightTheme: $lightGrey200
    ),
    svgHoverColor: (
        darkTheme: $darkBlue500,
        lightTheme: $lightGrey200
    ),
    cardBackgroundColor: (
        darkTheme: #1e1e2d,
        lightTheme: white
    ),
    cardShadow: (
        lightTheme: 0px 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 10%)
    ),
    cardBorderColor: (
        darkTheme: #2B2B40,
        lightTheme: #ebebeb
    ),
    cardTitleColor: (
        darkTheme: white,
        lightTheme: #1c1c2b
    ),
    headerBgColor: (
        darkTheme: #26263f,
        lightTheme: white
    ),
    sidebarBgColor: (
        darkTheme: #1c1c34,
        lightTheme: white
    ),
    sidebarShadow: (
        lightTheme: 4px 1rem 2rem 0rem rgb(0 0 0 / 15%)
    )
);

@mixin styles($mode) {
    @each $key, $map in $themes {
        @each $prop, $color in $map {
            @if $prop == $mode {
                --#{$key}: #{$color};
            }
        }
    }
}

.dark-mode {
    @include styles("darkTheme");
}

.light-mode {
    @include styles("lightTheme");
}
