.dashboardBreadcrumbComponent {

    .breadcrumbContainer {

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 25px;

        .title {
            font-family: 'Poppins';

            .pageTitle {
                font-size: 1.15rem;
                line-height: 22px;
            }

            .pageBreadcrumb {
                // font-weight: 600;
                color: #565674;
            }
        }

        .actions {

            .icoBtn {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                background-color: #2b2b40;
                height: calc(1.5em + 1.1rem);
                width: calc(1.5em + 1.1rem);

                border-radius: 7px;

                cursor: pointer;
    
                svg {
                    color: #565674;
                }

                &:hover {
                    background-color: #48486a;

                    svg { color: white; }
                }
            }
        }
    }

}