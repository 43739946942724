@import "~sweetalert2/dist/sweetalert2.css";

@import 'utilities/reset';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Montserrat:wght@400;700&family=Poppins:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400&display=swap');

@import 'utilities/darkMode';
@import 'utilities/swal';

@import 'animate.css';

body {
    font-family: 'Inter';
    color: var(--textColor);
}

.app {
    background: var(--bgColor);
    display: flex;
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
}

@import 'layouts/auth';
@import 'layouts/dashboard/global';
@import 'layouts/dashboard/profile';

@import 'pages/landingPage';
@import 'pages/streamHistoryPage';
@import 'pages/dashboardPage';

@import 'components/global/themeSwitcherComponent';

@import 'components/dashboard/layouts/mobileSidebar';
@import 'components/dashboard/layouts/sidebar';
@import 'components/dashboard/layouts/header';
@import 'components/dashboard/layouts/breadCrumb';
@import 'components/dashboard/layouts/card';

@import 'components/dashboard/middleware';

@import 'pages/dashboard/profile/overview';
@import 'pages/dashboard/tools/toolsPage';
@import 'pages/dashboard/tools/exportLikes';

@import 'pages/dashboard/utils/dashboardErrorPage';

@import 'pages/dashboard/homePage';